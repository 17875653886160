<template>
    <v-container fluid fill-height style="justify-content: center">
        <div>
            <alert ref="alertComponent"></alert>
            <playlist-form ref="playlistForm" buttonText="Add" @submit="submit($event)"></playlist-form>
        </div>
    </v-container>
</template>

<script>
import Alert from "../../components/Alert.component";
import PlaylistForm from "../../components/PlaylistForm.component";
import Services from '../../services/api';

export default {
    name: "NewPlaylist",
    components: {Alert, PlaylistForm},
    data: () => ({
        playlist: null
    }),
    methods: {
        /**
         * send the update request to the api
         * @returns {Promise<void>}
         */
        async submit(payload) {
            try {
                this.playlist = await Services.Playlists.addPlaylist(payload);

                this.$refs.alertComponent.showSuccessMessage("Playlist successfully added");
                this.$refs.playlistForm.setPlaylist(this.playlist);
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
            this.$refs.playlistForm.resetDefault();
        }
    }
}
</script>

<style scoped>

</style>