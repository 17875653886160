<template>
    <v-form ref="form" v-model="isValid" :lazy-validation="false">
        <v-container>

            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field v-model="playlist.titleVF" :counter="255" label="Title VF" color="#805acb"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field v-model="playlist.titleVO" :counter="255" label="Title VO" color="#805acb"
                                  :rules="[v => !!v || 'titleVO is required']"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field v-model="playlist.image" :counter="255" label="Image" color="#805acb"
                                  :rules="[v => !!v || 'Image is required']"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-select v-model="playlist.type" :items="types" label="Type" color="#805acb"></v-select>
                </v-col>

                <v-col cols="12" md="12">
                    <v-autocomplete v-model="playlist.videos" :items="videos" item-text="title" dense chips
                                    small-chips label="Videos" color="#805acb"
                                    multiple solo></v-autocomplete>
                </v-col>

                <v-btn color="success" class="mr-4" @click="submit">
                    {{ buttonText }}
                </v-btn>
                <v-btn color="warning" class="mr-4" @click="resetDefault">
                    Reset Form
                </v-btn>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "PlaylistForm",
    props: {
        buttonText: {
            required: true,
            type: String
        }
    },
    data: () => ({
        isValid: true,
        videos: [],
        oldPlaylist: {},
        playlist: {},
        types: ["MOVIE", "SERIES"]
    }),
    computed: {
        ...mapGetters(["user"]),
        /**
         * return the updated fields
         */
        getUpdatedData: function () {
            return {
                id: this.oldPlaylist.id,
                titleVF: (this.playlist.titleVF !== this.oldPlaylist.titleVF) ? this.playlist.titleVF : null,
                titleVO: (this.playlist.titleVO !== this.oldPlaylist.titleVO) ? this.playlist.titleVO : null,
                image: (this.playlist.image !== this.oldPlaylist.image) ? this.playlist.image : null,
                type: (this.playlist.type !== this.oldPlaylist.type) ? this.playlist.type : null,
                videos: JSON.stringify(this.playlist.videos) !== JSON.stringify(this.oldPlaylist.videos)
                    ? this.videos
                    : null,
            }
        },
    },
    methods: {
        setPlaylist(playlist) {
            this.oldPlaylist = {...playlist};
            this.playlist = playlist;
            this.resetDefault();
        },
        setVideos(videos) {
            this.allVideos = videos;
        },
        /**
         * send submit event to parent
         */
        submit() {
            if (!this.$refs.form.validate()) return;
            this.$emit('submit', this.getUpdatedData);
        },
        /**
         * reset form with default playlist values
         */
        resetDefault() {
            this.playlist = {...this.oldPlaylist};
        },
    }
}
</script>

<style scoped>

</style>